import styled from 'styled-components';
import tokens from '@te-digi/styleguide-tokens';
import { media } from '../utils/styles/media.js';

const ColumnConnector = styled.div.withConfig({
  componentId: "sg1730__sc-18jq642-0"
})(["border-top-left-radius:", ";border-top-right-radius:", ";border:1px solid ", ";border-bottom:0;display:none;height:", ";margin-left:calc(16.666666% - 11.5px);margin-right:calc(16.666666% - 11.5px);position:relative;", "{display:block;}&::after{border-left:1px solid ", ";bottom:0;content:'';left:calc(50% - 0.5px);position:absolute;top:0;}"], tokens.radius.md, tokens.radius.md, tokens.color['neutral-4'], tokens.space.md, media.xl, tokens.color['neutral-4']);
ColumnConnector.displayName = 'ColumnConnector';

export { ColumnConnector };
